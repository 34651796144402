import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchOrder } from '@general-data/store/actions/data.actions';
import { State } from '@core/store/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-search-header-field',
  templateUrl: './search-header-field.component.html',
  styleUrls: ['./search-header-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchHeaderFieldComponent {
  public searchInput = new FormControl('');
  public isOpen = false;

  constructor(private router: Router, private store: Store<State>) {}

  public search(): void {
    if (this.searchInput.value) {
      // this.router.navigate([`/app/search/${this.searchInput.value}`]);
      this.store.dispatch(SearchOrder({ trackingNumber: this.searchInput.value }));
      this.searchInput.reset();
    }
  }
}
