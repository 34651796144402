import { Pipe, PipeTransform } from '@angular/core';
// import * as _ from 'lodash';

@Pipe({
  name: 'unique',
  pure: false,
})
export class UniquePipe implements PipeTransform {
  transform(value: any, prop: any): any {
    // if(value!== undefined && value!== null){
    //     return _.uniqBy(value, 'name');
    // }
    // return value;
    if (value !== undefined && value !== null) {
      const cb = typeof prop === 'function' ? prop : (o: any) => o[prop];
      return [
        ...value
          .reduce((map: any, item: any) => {
            const key = item === null || item === undefined ? item : cb(item);

            map.has(key) || map.set(key, item);

            return map;
          }, new Map())
          .values(),
      ];
    }
  }
}
