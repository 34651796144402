<header>
  <div class="nav-logo">
    <button mat-icon-button class="navigation-menu-icon" (click)="onToggleMenu()">
      <mat-icon svgIcon="navigation-menu"></mat-icon>
    </button>
    <img class="logo" src="assets/logos/cerebro_logo.svg" [routerLink]="'/app/home'" alt="Cerebro" />

    <button mat-flat-button [matMenuTriggerFor]="moduleMenu" class="module-selector-btn">
      <mat-icon [svgIcon]="currentModule.icon"></mat-icon>
      <span>{{ currentModule.text }}</span>
    </button>
    <mat-menu #moduleMenu="matMenu">
      <a mat-menu-item [routerLink]="lobby.link">
        <mat-icon [svgIcon]="lobby.icon"></mat-icon>
        <span>{{ lobby.text }}</span>
      </a>
      <a mat-menu-item *ngIf="(hasDashboardsRoles$ | async)" [routerLink]="dashboardsModule.link">
        <mat-icon [svgIcon]="dashboardsModule.icon"></mat-icon>
        <span>{{ dashboardsModule.text }}</span>
      </a>
      <a mat-menu-item *ngIf="(hasOperationsRoles$ | async)" [routerLink]="operationsModule.link">
        <mat-icon [svgIcon]="operationsModule.icon"></mat-icon>
        <span>{{ operationsModule.text }}</span>
      </a>
      <a mat-menu-item *ngIf="(hasAdminRoles$ | async)" [routerLink]="sysAdminModule.link">
        <mat-icon [svgIcon]="sysAdminModule.icon"></mat-icon>
        <span>{{ sysAdminModule.text }}</span>
      </a>
    </mat-menu>
  </div>

  <!-- <mat-icon [svgIcon]="'search'"></mat-icon> -->
  <app-search-header-field></app-search-header-field>

  <div class="user-info-wrapper">
    <ng-container *ngIf="userProfile$ | async as profile">
      <div class="user-info" [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="arrow-head-down-bold"></mat-icon>
        <div class="name-location">
          <div class="name">{{ profile.name }}</div>
          <div class="location">{{ profile.primaryHubName }}</div>
        </div>
        <img class="flag" [src]="'assets/flags/' + profile.country + '.svg'" [alt]="profile.country" />
      </div>
    </ng-container>
  </div>
</header>

<mat-menu #menu="matMenu">
  <h4>User settings</h4>
  <button mat-icon-button class="close-btn">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
  <ul>
    <!-- <li>
      <a mat-menu-item (click)="openAccountDetails()"><mat-icon [svgIcon]="'user'"></mat-icon>Account details</a>
    </li> -->
    <li>
      <a mat-menu-item (click)="switchHub()"><mat-icon [svgIcon]="'hub'"></mat-icon>Switch hub</a>
    </li>
    <li>
      <a mat-menu-item (click)="logout()"><mat-icon [svgIcon]="'logout'"></mat-icon>Logout</a>
    </li>
  </ul>
</mat-menu>
