<section [ngClass]="{ isHidden: isHidden }">
  <header>
    <button mat-icon-button (click)="toggleVisibility()">
      <mat-icon [svgIcon]="'arrow-head-down'" [ngClass]="{ isHidden: isHidden }"></mat-icon>
    </button>

    <h1>Barcode field</h1>
  </header>
  <div class="body">
    <mat-form-field>
      <mat-label>Enter barcode number</mat-label>
      <input
        matInput
        #barCodeInput
        #ngModel="ngModel"
        [(ngModel)]="barcodeVal"
        (ngModelChange)="onBarcodeChange($event)"
        (keydown.enter)="sendIt()"
        (keydown.tab)="sendIt()"
        autofocus
        cdkFocusInitial
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <div class="buttons-wrapper">
    <mat-slide-toggle color="accent" (change)="onManualInputToggle($event)">Manual input</mat-slide-toggle>
    <button mat-stroked-button color="accent" (click)="sendIt()" [disabled]="!isManualInput">Submit</button>
  </div>
</section>

<!-- <app-scanner-field></app-scanner-field> -->
