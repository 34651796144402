import { createSelector } from '@ngrx/store';
import { adapter, ScanInState } from '../reducer/scan-in.reducer';
import { selectScanInState } from '../index';
import { selectRouteParam } from '@core/store/selectors/router.selectors';

// entity get the selectors (ReceivingOrders)
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
// select the array of order ids
export const selectAllScanInOrdersIds = selectIds;
// select the dictionary of order entities
export const selectAllScanInOrdersEntities = selectEntities;
// select the array of orders
export const selectAllScanInOrders = selectAll;
// select the total order count
export const selectAllScanInOrdersTotal = selectTotal;

export const selecScanInOrdersIds = createSelector(selectScanInState, selectAllScanInOrdersIds);
export const selecScanInOrdersEntities = createSelector(selectScanInState, selectAllScanInOrdersEntities);
export const selecScanInOrders = createSelector(selectScanInState, selectAllScanInOrders);
export const selecScanInOrdersTotal = createSelector(selectScanInState, selectAllScanInOrdersTotal);

export const selectShelves = createSelector(selectScanInState, (state: ScanInState) => state && state.shelves);
export const selectSelectedShelf = createSelector(
  selectScanInState,
  (state: ScanInState) => state && state.selectedShelf
);

export const selectScanningId = createSelector(selectScanInState, (state: ScanInState) => state && state.scanningId);
export const selectLastScan = createSelector(selectScanInState, (state: ScanInState) => state && state.lastScan);
export const selectLoading = createSelector(selectScanInState, (state: ScanInState) => state && state.loading);
export const selectError = createSelector(selectScanInState, (state: ScanInState) => state && state.error);

// Selector to get order packages from the order in router param
export const selectOrderPackages = createSelector(
  selecScanInOrdersEntities,
  selectRouteParam('mp'),
  (entities, orderId) => {
    if (entities && orderId) {
      return entities[orderId]?.packages;
    }
    return null;
  }
);
