import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs!: Breadcrumb[];
  @Input() packages!: { packages: string[]; scanned: number } | null;
  @Input() totalNumber!: Observable<number | null>;

  constructor(private router: Router) {}

  public navigate(url: string): void {
    this.router.navigate([url], { queryParams: null });
  }
}
