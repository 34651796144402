import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime',
})
export class MinutesToTime implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 60);
    let srtHours = hours.toString();
    if (srtHours.length !== 2) {
      srtHours = '0' + srtHours;
    }
    const minutes = Math.floor(value % 60);
    let srtMinutes = minutes.toString();
    if (srtMinutes.length !== 2) {
      srtMinutes = '0' + srtMinutes;
    }
    return srtHours + ':' + srtMinutes;
  }
}
