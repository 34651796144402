import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ScannerFieldDialogComponent } from '@shared/dialogs/scanner-field-dialog/scanner-field-dialog.component';

declare const BarcodeReader: any;

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  public readonly newScan$ = new BehaviorSubject<string>('');
  public get scannerApiLoaded$(): Observable<boolean> {
    return this.scannerLoaded$.asObservable();
  }

  public get instance(): any {
    return this.barcodeReader;
  }

  private barcodeReader: any;
  private resolveScannerLoaded!: (value: void | PromiseLike<void>) => void;
  private readonly scannerLoaded$ = new BehaviorSubject<boolean>(false);
  private dialogRef!: MatDialogRef<ScannerFieldDialogComponent>;
  private isCancelled: boolean = false;
  private scannerClosed: boolean = false;

  constructor(private dialog: MatDialog) {}

  public loadScanner(): void {
    this.scannerClosed = false;
    this.scannerLoaded$.next(false);
    this.load().then(() => {
      this.scannerLoaded$.next(true);
    });
  }

  public closeScannerPopup(): void {
    this.scannerClosed = true;
    this.clearScannerValue();
    if (this.dialogRef) {
      this.dialogRef.close();
      this.isCancelled = false;
    }
    if (this.barcodeReader) {
      this.barcodeReader.removeEventListener('barcodedataready', this.onBarcodeDataReady);
    }
    // to clean the last scan value after closing the dialog
    this.clearScannerValue();
  }

  public setNewManualScan(barcode: string): void {
    this.newScan$.next(barcode);
  }

  public clearScannerValue(): void {
    this.newScan$.next('');
  }

  public load(): Promise<void> {
    return new Promise((resolve) => {
      this.resolveScannerLoaded = resolve;
      const id = 'scanner-lib';

      // Check if script has been loaded already. If so, init
      if (document.getElementById(id)) {
        this.init();
      } else {
        // Load script, then init

        const src = '/assets/js/BarcodeReader.js';
        const script: HTMLScriptElement = document.createElement('script');

        script.type = 'text/javascript';
        script.id = id;
        script.async = true;
        script.src = src;
        document.getElementsByTagName('head')[0].appendChild(script);

        script.onload = () => {
          // console.log('BarcodeReader', BarcodeReader);
          this.init();
        };
      }
    });
  }

  public init(): void {
    if (!this.barcodeReader) {
      this.barcodeReader = new BarcodeReader(null, this.onBarcodeReaderComplete.bind(this));
    } else {
      this.resolveScannerLoaded();
    }
  }

  private onBarcodeReaderComplete(result: any): void {
    if (result.status === 0) {
      // It is mobile device with scanner
      // BarcodeReader object was successfully created.
      // Configure the symbologies needed. Buffer the settings
      // and commit them at once.
      this.barcodeReader.setBuffered('Symbology', 'Code39', 'Enable', 'true');
      this.barcodeReader.setBuffered('Symbology', 'Code128', 'EnableCode128', 'true');
      this.barcodeReader.commitBuffer(this.onCommitComplete.bind(this));

      // Add an event handler for the barcodedataready event
      this.barcodeReader.addEventListener('barcodedataready', this.onBarcodeDataReady.bind(this), false);
      this.resolveScannerLoaded();
    } else if (this.scannerClosed === false) {
      // It is browser/mobile without scanner
      this.barcodeReader = null;
      console.warn('Failed to create BarcodeReader, status: ' + result.status + ', message: ' + result.message);
      this.openScannerPopup();
    }
  }

  // Verify the symbology configuration.
  private onCommitComplete(resultArray: any): void {
    if (resultArray.length > 0) {
      for (let i = 0; i < resultArray.length; i++) {
        const result = resultArray[i];
        if (result.status !== 0) {
          alert(
            'Failed to set setting, Family: ' +
              result.family +
              ' Key: ' +
              result.key +
              ' Option: ' +
              result.option +
              ', status: ' +
              result.status +
              ', message: ' +
              result.message
          );
        }
      } // endfor
    }
  }

  private onBarcodeDataReady(data: string, _type: any, _time: any): void {
    // console.log('data', data);
    this.newScan$.next(data);
  }

  private openScannerPopup(): void {
    if (this.isCancelled) {
      this.isCancelled = false;
      return;
    }
    this.dialogRef = this.dialog.open(ScannerFieldDialogComponent, {
      disableClose: true,
      // hasBackdrop: false,
      panelClass: 'scan-dialog-pane',
      backdropClass: 'scan-dialog-backdrop',
    });

    // this.resolveScannerLoaded();
    if (this.dialogRef.getState() === MatDialogState.OPEN) {
      // The dialog is opened.
      this.resolveScannerLoaded();
    }
  }
}
