<ul class="breadcrumbs">
  <li *ngFor="let breadcrumb of breadcrumbs; let i = index" [attr.data-index]="i">
    <!-- <a *ngIf="(breadcrumbs.length !== (i+1) && breadcrumb.url) else noLink;" href="#">{{ breadcrumb.label }}</a> -->
    <a *ngIf="(breadcrumb.url) else noLink;" (click)="navigate(breadcrumb.url)">{{ breadcrumb.label | uppercase }}</a>
    <ng-template #noLink> {{ breadcrumb.label | uppercase }} </ng-template>
  </li>
</ul>

<div *ngIf="packages && packages.packages.length" class="packages">
  Total scanned packages: {{ packages.scanned }} of {{packages.packages.length}}
  <mat-icon svgIcon="show" matTooltipClass="tooltip-multiline" [matTooltip]="packages.packages.join('\n')"></mat-icon>
</div>

<div *ngIf="totalNumber | async as total" class="packages">Total: {{ total }}</div>
