import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from '@core/store/reducers';
import { SideBarComponents } from '@shared/types/side-bar-components.type';
import { SidebarLoaderService } from '@shared/services/sidebar-loader.service';
import { NgModel } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ScannerService } from '@shared/services/scanner.service';

@Component({
  selector: 'app-scanner-field-dialog',
  templateUrl: './scanner-field-dialog.component.html',
  styleUrls: ['./scanner-field-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerFieldDialogComponent implements OnDestroy {
  @ViewChild('barCodeInput') public barCodeInput!: ElementRef;
  @ViewChild('ngModel') public ngModel!: NgModel;
  public barcodeVal: string = '';
  public prevCodeVal: string = '';
  public isHidden: boolean = false;
  public multiPackagesTrackingNumber: string | null = null;
  public isManualInput: boolean = false;

  constructor(
    public dialog: MatDialog,
    private store: Store<State>,
    private sidebarLoaderService: SidebarLoaderService,
    public dialogRef: MatDialogRef<ScannerFieldDialogComponent>,
    public dialogAllRef: MatDialog,
    private scannerService: ScannerService
  ) {}

  ngOnDestroy(): void {
    this.scannerService.clearScannerValue();
  }

  public toggleVisibility(): void {
    this.isHidden = !this.isHidden;
  }

  public sendIt(): void {
    if (this.barcodeVal) {
      this.prevCodeVal = '';
      this.scannerService.setNewManualScan(this.barcodeVal);
      this.barcodeVal = '';
    }
  }

  public openSideNav(sidebarComp: SideBarComponents): void {
    this.sidebarLoaderService.loadSideBarComp(sidebarComp);
  }

  public close(): void {
    this.sidebarLoaderService.destroySideBarComp();
    this.dialogRef.close();
    this.barcodeVal = '';
  }

  public onManualInputToggle(event: MatSlideToggleChange): void {
    this.isManualInput = event.checked;
  }

  public onBarcodeChange(_val: string): void {
    if (!this.isManualInput) {
      // add a delay to allow the scanner to finish scanning
      setTimeout(() => {
        this.sendIt();
        this.barCodeInput.nativeElement.value = '';
      }, 200);
    }
  }
}
