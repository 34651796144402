<div class="filters-settings">
  <div class="filters" *ngIf="appliedFilters | async as filters">
    <!-- <span>Filters applied:</span> -->
    <a (click)="onOpenFilters()"> {{ Object.keys(filters).length ? 'Modify' : 'Open' }} filters</a>

    <mat-chip-listbox aria-label="Filters applied" *ngIf="appliedFilters | async as appliedFilters">
      <mat-chip *ngFor="let filter of appliedFilters | keyvalue"
        >{{ filter.key | humanize }}: <span>{{ checkTypeOfValue(filter.value, filter.key) }}</span>
      </mat-chip>
    </mat-chip-listbox>
  </div>
  <div class="settings" *ngIf="columns">
    <button mat-icon-button [matMenuTriggerFor]="columnOpts">
      <mat-icon [svgIcon]="'setting-vertical'"></mat-icon>
    </button>
    <!-- <mat-menu #columnOpts="matMenu" panelClass="colsConfig"> -->
    <mat-menu #columnOpts="matMenu" class="table-settings-menu" panelClass="table-settings-menu">
      <div class="menu-header">
        <h4>Table settings</h4>
        <button mat-icon-button (click)="closeMenu()">
          <mat-icon class="icon-close" [svgIcon]="'close'"></mat-icon>
        </button>
      </div>

      <div class="info-message">Reorder and switch on/off columns as you need.</div>

      <div class="colsConfig">
        <p>EDITABLE COLUMNS</p>
        <div cdkDropList (cdkDropListDropped)="onDropColumn($event)" class="drag-list">
          <div *ngFor="let column of columns" class="colsMenuItem" cdkDrag [cdkDragDisabled]="column.stickyEnd">
            <ng-container *ngIf="!column.stickyEnd">
              <mat-icon [svgIcon]="'reorder'"></mat-icon>
              <mat-slide-toggle
                [checked]="!column.hide"
                labelPosition="before"
                (change)="onViewColChange($event, column.field)"
                (click)="$event.stopPropagation()"
                >{{ column.headerName }}</mat-slide-toggle
              >
            </ng-container>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
