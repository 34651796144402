import { Pipe, PipeTransform } from '@angular/core';
import { PackagesResponse } from '@core/interfaces/scan-response.interface';

@Pipe({ name: 'countScanned' })
export class CountScanned implements PipeTransform {
  transform(packages: PackagesResponse[] | undefined): number {
    if (!packages) {
      return 0;
    }
    return packages.filter((pkg) => pkg.scanningStatus === 'scanned').length;
  }
}
