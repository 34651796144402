<button mat-flat-button class="search-header-field-mobile" (click)="isOpen=!isOpen" [ngClass]="isOpen ? 'isOpen' : ''">
  <mat-icon [svgIcon]="'search'"></mat-icon>
</button>
<div class="search-header-field">
  <mat-form-field appearance="outline">
    <button mat-button matPrefix aria-label="search" [routerLink]="'/app/search'">
      <mat-icon matSuffix [svgIcon]="'settings-slider'"></mat-icon>
    </button>
    <!-- <mat-label>Fill form field</mat-label> -->
    <input matInput placeholder="Search" [formControl]="searchInput" (keyup.enter)="search()" />
    <button mat-button matSuffix aria-label="search" (click)="search()">
      <mat-icon matSuffix [svgIcon]="'search'"></mat-icon>
    </button>
  </mat-form-field>
</div>
