<button
  mat-stroked-button
  class="main-button"
  *ngIf="whiteButton"
  [disabled]="(whiteButton && whiteButton.disabled) ? (whiteButton.disabled) : false"
  (click)="whiteButton.action()"
>
  {{ whiteButton.text }}
</button>

<button
  mat-raised-button
  color="accent"
  class="main-button"
  *ngIf="blueButton"
  [disabled]="(blueButton && blueButton.disabled) ? (blueButton.disabled) : false"
  (click)="blueButton.action()"
>
  {{ blueButton.text }}
</button>

<button
  mat-raised-button
  color="primary"
  class="main-button"
  *ngIf="blackButton"
  [disabled]="(blackButton && blackButton.disabled) ? (blackButton.disabled) : false"
  (click)="blackButton.action()"
>
  {{ blackButton.text }}
</button>

<div class="dropdown-button-wrapper" *ngIf="dropDownButton && dropDownButton.length">
  <div class="dropdown-button-wrapper" *ngIf="dropDownButton">
    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="navigation-vertical-menu"></mat-icon>
    </button>

    <mat-menu #menu="matMenu" class="dropdown-menu" panelClass="dropdown-menu" [overlapTrigger]="true">
      <div class="menu-header">
        <h4>Action menu</h4>
        <button mat-icon-button (click)="closeMenu()">
          <mat-icon class="icon-close" [svgIcon]="'close'"></mat-icon>
        </button>
      </div>

      <ng-container *ngFor="let menuItem of dropDownButton; let i = index">
        <div class="menu-group" *ngIf="menuItem.show !== false">
          <mat-divider *ngIf="i !== 0"></mat-divider>
          <div *ngIf="menuItem.title" class="title">{{ menuItem.title }}</div>

          <div class="menu-item" *ngFor="let button of menuItem.items">
            <button
              mat-menu-item
              *ngIf="button.show === undefined || button.show === true"
              [ngClass]="{'red': button.isRed}"
              (click)="button.action()"
              [disabled]="button.linkDisabled"
            >
              <span>{{ button.text }}</span>
            </button>
          </div>
        </div>
      </ng-container>
    </mat-menu>
  </div>
</div>
